:root {
  --fontFamily: "poppins";
  --dark-green-1: #003929;
  --dark-green-2: #007251;
  --green: #00ab79;
  --light-green-1: #00e4a2;
  --light-green-2: #72ffd6;
  --off-white-1: #eeeef7;
  --off-white-2: #f5f5f5;
  --dark-blue-1: #3f3d6a;
  --dark-blue-2: #272752;
  --dark-blue-3: #3f60a0;
  --dark-blue-4: #9c9cd0;
  --dark-blue-5: #3f60a0;
  --light-purple-1: #9c9cd0;
  --dark-gray-1: #3b3b3b;
  --color-1: #242424;
  --color-2: #345c72;
  --color-3: #ffffff;
  --color-4: #2a2e30;
}

::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--dark-blue-2);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d8d8d8;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #d8d8d8;
}

h2 {
  font-family: "poppins";
  font-size: 23px;
}

h1 {
  font-family: "Poppins";
  font-size: 28px;
}

h3 {
  font-family: "Poppins";
  font-size: 18px;
}

html,
body,
h5,
h4,
h6 {
  font-family: "poppins";
}

body {
  font-family: "Poppins";
  font-size: 14px;
}

p {
  font-family: "Poppins";
  font-size: 14px;
}

.padding-horizontal-small {
  padding: 0 5px;
}

@media only screen and (max-width: 768px) {
  .gru-input {
    padding-top: 7px;
    padding-bottom: 7px;
  }

  .gru-button {
    padding-top: 7px;
    padding-bottom: 7px;
  }
}

/* Test */

/* .container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.column {
    height: 400px;
    width: 200px;
    margin: 20px;
    border-radius: 10px;
    box-shadow: 1px 1px 6px rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.first-column {
    background-color: #f5ffea;
}

.second-column {
    background-color: #fffbf5;
}

.movable-item {
    border-radius: 10px;
    background-color: #fff3f3;
    height: 100px;
    width: 170px;
    margin: 10px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 3px rgba(0,0,0,0.5);
} */
/*  */

/**
   *  Gret Manage styling.
   */

input[type="color"] {
  -webkit-appearance: none;
  border: none;
  height: 30px;
  background: none;
  border-radius: 8px;
}
input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}
input[type="color"]::-webkit-color-swatch {
  border: none;
}
.loading_modal {
  position: absolute;
  border: 1px solid rgb(204, 204, 204);
  background-color: var(--off-white-1);
  overflow: auto;
  outline: none;
  padding: 2%;
  width: 100%;
  height: 100%;
  opacity: 0.7;
}

.loading_modal_content {
  /* background-color: white; */
  border-radius: 20px;
  padding: 9%;
}
.link_button {
  border: none;
  background-color: inherit;
  text-decoration: underline;
  cursor: pointer;
}

.gret-button {
  border-radius: 8px;
  cursor: pointer;
  outline: none;
  padding-top: 1%;
  padding-right: 9%;
  padding-bottom: 1%;
  padding-left: 9%;
  text-decoration: none;
}

.create_grid {
  background-color: white;
  color: var(--dark-blue-2);
  display: grid;
  grid-template-columns: 33% 33% auto;
  width: 70%;
  margin-left: 15%;

  margin-top: 3%;
}
.create_card {
  cursor: pointer;
  display: grid;
  background-color: var(--off-white-1);
  width: 70%;
  margin-left: 15%;
  text-align: center;
  border-radius: 8px;
}
.create_card a {
  text-decoration: none;
}
.create_img {
  width: 100%;
  height: 25vh;
}

@media only screen and (max-width: 600px) {
  .ScreenCount {
    display: flex;
  }
  .designPageLayout {
    color: rgb(39, 39, 82);
    display: grid;
    grid-template-rows: auto 75% auto;
  }
  .icons_style {
    width: 100% !important;
  }
  .icon_button {
    width: 15%;
    bottom: 7%;
    left: 0%;
    position: fixed !important;
    border: none;
    outline: none;
    background-color: inherit;
    cursor: pointer;
    padding: 0%;
  }
  .option_div {
    overflow: hidden;
    position: fixed !important;
    bottom: 7%;
    width: 12%;
    height: 0px;
    left: 2%;
    max-height: calc(-63px + 100vh);
    min-height: 50%;
    max-width: calc(-24px + 100vw);
    background-color: var(--off-white-1);
    display: grid;
    grid-template-rows: auto;
    border-radius: 8px;
    transition-delay: 1s;
    z-index: 1;
  }

  .option_button {
    width: 100%;
    border: none;
    outline: none;
    background-color: inherit;
    cursor: pointer;
    padding: 0%;
  }
  .blue_button {
    width: 78%;
  }
  .login_margin {
    padding: 2px 20px;
  }
  .signup_grid {
    grid-template-columns: 100%;
    grid-template-rows: auto;
    row-gap: 10%;
    display: grid;
    width: 70%;
    margin-right: 10%;
    margin-left: 10%;
    margin-top: 5%;
    height: 100%;
  }
}
@media only screen and (min-width: 600px) {
  .landing-section {
    height: 90vh;
    padding: 25px 160px 25px 100px;
  }
  .large-section {
    height: 90vh;
    padding: 25px 100px 25px 100px;
  }
  .ScreenCount {
    display: grid;
    grid-template-columns: 20% 15% repeat(9, 5%);
  }
  .blue_button {
    width: 60%;
  }
  .login_margin {
    padding: 8px 10%;
  }
  .signup_grid {
    /* grid-template-columns: 33% 33% 33%;
        grid-template-rows: 100% 100%;
        display: grid;
        margin-right: 10%;
        margin-left: 10%;
        margin-top: 5%;
        height: 100%; */
    grid-template-columns: 33% 33% 33%;
    grid-template-rows: 100% 100%;
    column-gap: 10%;
    display: grid;
    width: 70%;
    margin-right: 10%;
    margin-left: 10%;
    margin-top: 5%;
    height: 100%;
  }
}
input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.signup_input {
  padding: 8px;
  display: block;
  border: 1px solid var(--dark-blue-3);
  width: 75%;
  color: var(--dark-blue-2);
  background-color: var(--off-white-1);
  border-radius: 8px;
}
.input_container {
  padding-top: 1%;
  padding-left: 2%;
  padding-right: 2%;
  padding-bottom: 2%;
}
.login_button {
  display: inline-block;
  padding: 8px 3%;
  margin: 1%;
  color: var(--dark-blue-2);
  background-color: var(--off-white-1);
  text-align: center;
  cursor: pointer;
  border: none;
  border-radius: 8px;
  width: "70%";
}
.signup_button {
  display: inline-block;
  padding: 8px 3%;
  color: var(--off-white-1);
  background-color: var(--dark-blue-2);
  text-align: center;
  cursor: pointer;
  border: none;
  border-radius: 8px;
  text-decoration: none;
}
button:disabled,
button[disabled] {
  background-color: #cccccc;
  color: #666666;
}

.signup_checkbox {
  height: 14px;
  width: 14px;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .level_section {
    height: 20vh;
  }
  .save_icon {
    margin-block-start: 0em;
    margin-block-end: 0em;
  }
  .heading_text {
  }
}
@media screen and (min-width: 600px) {
  .level_section {
    min-height: 100vh;
  }
  .save_icon {
  }
  .heading_text {
  }
}

.bigScreenHeading {
  display: grid;
  grid-template-columns: 80% 20%;
}
.quest_border {
  border-right: 2px solid var(--color-3);
}
.modal_close {
  border: none;
  background-color: inherit;
  color: var(--color-3);
  border-radius: 8px;
  cursor: pointer;
  font-size: 20px;
}

.quest_layout {
  display: grid;
  grid-template-columns: 20% 60% 20%;
  height: 100vh;
}
.questAddnew {
  display: inline-block;
  padding: 8px 3%;
  color: var(--color-3);
  background-color: var(--color-1);
  text-align: center;
  cursor: pointer;
  border: none;
  border-radius: 8px;
  width: 80%;
  margin: 3% 0% 3% 0%;
}
.level_button {
  display: inline-block;
  padding: 8px 3%;
  color: var(--dark-blue-2);
  background-color: var(--off-white-1);
  text-align: center;
  cursor: pointer;
  border: none;
  border-radius: 8px;
}
.choose_quest_layout {
  display: grid;
  grid-template-columns: 50% 50%;
  margin-top: 5%;
  width: 70%;
  column-gap: 33%;
  row-gap: 10%;
}
hr {
  border: 0;
  border-top: 2px solid var(--off-white-1);
  width: 70%;
  margin-left: 15%;
}
#div1 {
  width: 100%;
  height: 100%;
  padding: 10px;
}

.button_div {
  color: var(--dark-blue-2);
}
.button_div button {
  position: absolute;
  left: 69%;
  padding: 9px 3%;
  width: 9%;
}

/* Quest check box css starts_________________________________________________________ */
.wrapper {
  width: 100%;
  margin-top: 2%;
  margin-left: 7%;
}
.container {
  display: grid;
  justify-content: space-evenly;
  grid-template-columns: 50% 50%;
  padding-left: 10%;
}
.option_item {
  position: relative;
  width: 80%;
}
.container .option_item .checkbox {
  position: absolute;
  opacity: 0;
}
.option_item .option_inner {
  width: 100%;
  background: var(--off-white-1);
  border-radius: 8px;
  text-align: center;
  box-shadow: 1px 2px var(--off-white-1);
  cursor: pointer;
  color: var(--dark-blue-2);
  display: block;
  border: 3px solid transparent;
  position: relative;
  padding-bottom: 3%;
  padding-top: 3%;
}

.option_item .option_inner .name {
  margin-top: 1%;
  padding-top: 2%;
}

.checkbox:checked ~ .option_inner.facebook {
  /* border-color: var(--off-white-1); */
  color: var(--dark-blue-2);
}
.tickmark {
  position: relative;
  top: 23%;
  left: 40%;
  display: none;
}

.tickmark:before {
  content: "";
  position: absolute;
  width: 31px;
  height: 15px;
  border: 6px solid;
  border-color: transparent transparent var(--color-1) var(--color-1);
  transform: rotate(-45deg);
}
.level_tickmark {
  content: "";
  position: relative;
  width: 31px;
  height: 15px;
  border: 6px solid;
  border-color: transparent transparent var(--dark-blue-2) var(--dark-blue-2);
  transform: rotate(-45deg);
}

.option_item .checkbox:checked ~ .option_inner .tickmark {
  display: block;
}

.tickmark {
  border-color: transparent transparent transparent transparent;
}

/* Quest checkbox css ends __________________________________________________*/

.dashboard_layout {
  display: grid;
  grid-template-columns: 10% 70% 20%;
  /*height: 100vh*/
}
.createVideo {
  display: grid;
  grid-template-columns: 100%;
  width: 100%;
}
@media only screen and (max-width: 600px) {
  .landing-section {
    height: auto;
    padding: 16px 32px;
  }
  .large-section {
    height: 90vh;
    padding: 16px 32px;
  }
  .quest_border {
    border-right: 2px solid var(--color-3);
    height: auto;
  }
  .dashboard_cards {
    display: grid;
    grid-template-columns: 100%;
    grid-column-gap: 1%;
    color: var(--off-white-1);
  }
  .dashboard_icons_1 {
    width: 100%;
    margin-left: 0%;
    margin-top: 45%;
  }
  .dashboard_numbers {
    /*text-align: left;*/
  }
  .dashboard_icons {
    width: 40px;
    position: absolute;
    left: 0%;
  }
}
.form_input {
  padding: 8px;
  display: block;
  border: none;
  border-bottom: 2px solid var(--color-1);
  width: 75%;
  color: var(--color-1);
  background-color: "white";
  border-radius: 8px;
}
.form_input:focus {
  border: none;
  border-bottom: 2px solid var(--dark-blue-2);
  outline: none;
}
@media only screen and (min-width: 600px) {
  .quest_border {
    border-right: 2px solid var(--color-3);
    min-height: 100vh;
  }
  .gretLogo {
    width: 100%;
  }
  .dashboard_cards {
    display: grid;
    grid-template-columns: 31% 31% 31%;
    grid-column-gap: 1%;
    color: var(--off-white-1);
  }
  .dashboard_icons_1 {
    width: 50%;
    margin-left: 50%;
  }
  .dashboard_numbers {
    text-align: left;
  }
  .dashboard_icons {
    width: 50px;
    position: absolute;
    left: 3%;
  }
}
.dashboard_cards > div {
  background-color: var(--dark-blue-2);
  border-radius: 8px;
  color: var(--off-white-1);
  margin: 2%;
}
.design_button {
  width: 70%;
  margin-top: 10%;
  background-color: inherit;
  color: var(--dark-blue-2);
  display: inline-block;
  padding: 8px 3%;
  text-align: center;
  cursor: pointer;
  border: none;
  border-radius: 8px;
}
.color_input {
  display: block;
  border-radius: 8px;
}
.color_input::-webkit-color-swatch {
  border: none;
  border-radius: 8px;
  padding: 0;
}

.create_card button {
  color: var(--dark-blue-2);
}
.editButton {
  cursor: pointer;
  background: inherit;
  margin: 0%;
  padding: 0%;
  outline: none;
  border: none;
}

.gru-disabled,
.gru-button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.gru-disabled *,
:disabled * {
  pointer-events: none;
}
.gru-disabled:hover,
.gru-button:disabled:hover {
  box-shadow: none;
}

.gru-button-wide {
  width: 100%;
}

.gru-button.gru-blue {
  border: 1px solid var(--dark-green-1);
  /*background-image: linear-gradient(var(--dark-green), #3ea1c8);*/
}

.gru-button.gru-yellow {
  border: 1px solid #ffd75c;
  /*background-image: linear-gradient(#ffd75c, #FCD868);*/
}

.gru-button.gru-grey {
  border: 1px solid #d8d8d8;
  /*background-image: linear-gradient(#d8d8d8, #c9c9c9);*/
}

.gru-button.gru-light-grey {
  border: 1px solid #f0f0f0;
  /*background-image: linear-gradient(#f0f0f0, #e0e0e0);*/
}

/**
   *  Gru Dashboard button styling.
   */

.gru-dashboard-button {
  border: 2px solid #aaaaaa;
  font-family: "poppins";
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  cursor: pointer;
  outline: none;
  padding: 8px 16px;
  background: #ffffff;
}

.gru-dashboard-button.hover-blue {
  border: 2px solid var(--dark-green-1);
}

.gru-dashboard-button.hover-yellow {
  border: 2px solid #ffd75c;
}

.gru-dashboard-button.hover-grey {
  border: 2px solid #d8d8d8;
}

.gru-dashboard-button.hover-light-grey {
  border: 2px solid #f0f0f0;
}

.gru-dashboard-button.hover-blue:hover {
  box-shadow: 1px 1px 1px 1px #aaaaaa;
  animation: to_blue;
  animation-fill-mode: forwards;
  animation-duration: 0.1s;
  animation-iteration-count: 1;
}

.gru-dashboard-button.hover-yellow:hover {
  box-shadow: 1px 1px 1px 1px #aaaaaa;
  animation: to_yellow;
  animation-fill-mode: forwards;
  animation-duration: 0.1s;
  animation-iteration-count: 1;
}

.gru-dashboard-button.hover-grey:hover {
  box-shadow: 1px 1px 1px 1px #aaaaaa;
  animation: to_grey;
  animation-fill-mode: forwards;
  animation-duration: 0.1s;
  animation-iteration-count: 1;
}

.gru-dashboard-button.hover-light-grey:hover {
  box-shadow: 1px 1px 1px 1px #aaaaaa;
  animation: to_light_grey;
  animation-fill-mode: forwards;
  animation-duration: 0.1s;
  animation-iteration-count: 1;
}

.gru-dashboard-button.hover-yellow:active,
.gru-dashboard-button.hover-blue:active,
.gru-dashboard-button.hover-grey:active {
  box-shadow: none;
}

@keyframes to_blue {
  0% {
    background: #ffffff;
    box-shadow: none;
  }
  100% {
    color: white;
    background: var(--dark-green-1);
    box-shadow: 1px 1px 1px 1px #aaaaaa;
  }
}

@keyframes to_yellow {
  0% {
    background: #ffffff;
    box-shadow: none;
  }
  100% {
    background: #ffd75c;
    box-shadow: 1px 1px 1px 1px #aaaaaa;
  }
}

@keyframes to_grey {
  0% {
    background: #ffffff;
    box-shadow: none;
  }
  100% {
    background: #d8d8d8;
    box-shadow: 1px 1px 1px 1px #aaaaaa;
  }
}

@keyframes to_light_grey {
  0% {
    background: #ffffff;
    box-shadow: none;
  }
  100% {
    background: #f0f0f0;
    box-shadow: 1px 1px 1px 1px #aaaaaa;
  }
}

[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
  z-index: 100;
}
[data-title]:after {
  content: attr(data-title);
  background-color: var(--off-white-1);
  color: var(--dark-blue-2);
  font-size: 80%;
  position: absolute;
  padding: 1px 5px 2px 5px;
  bottom: -3vh;
  left: 50%;
  white-space: nowrap;
  box-shadow: 1px 1px 3px #222222;
  opacity: 0;
  border-radius: 5px;
  z-index: 99999;
  visibility: hidden;
}
[data-title] {
  position: relative;
}
/* Gru Sort Styling */

.gru-sort {
  cursor: pointer;
}

.gru-sort:hover {
  background: #f0f0f0;
}

/* Gru Select Styling */
.gru-select {
  border-radius: 5px;
  background-color: white;
  border: 1px solid var(--dark-green-1);
  cursor: pointer;
  padding: 4px 12px;
  outline: none;
  overflow: hidden;
}

.gru-select-medium {
  padding: 4px 10px;
  border: 1px solid #aaaaaa;
  background-color: #f0f0f0;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
  overflow: hidden;
}

/* Screen Only */
@media screen {
  .no-print {
    display: block;
  }

  .no-show {
    display: none;
  }
}

/* Print Only */
@media print {
  .no-print {
    display: none;
  }

  .no-show {
    display: block;
  }

  .must-print {
    display: block;
  }
}

/* a tag Styling */

a,
u {
  /*text-decoration: none !important;*/
}

.gru-sidebar-item {
  margin: 0 16px 0 0;
}

.gru-sidebar-item:hover {
  background-color: #d8d8d8;
}

/* TopNav Styling */

ul.topnav {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  list-style-type: none;
  white-space: nowrap;
  margin: 0;
  padding: 0;
  width: inherit;
  height: auto;
  overflow-x: auto;
  overflow-y: hidden;
  background-color: #f0f0f0;
}

ul.topnav li {
  float: left;
}

ul.topnav li a {
  display: inline-block;
  color: black;
  text-align: center;
  padding: 10px 10px;
  text-decoration: none;
  border-radius: 4px;
}

ul.topnav li a:hover:not(.active) {
  background-color: #d8d8d8;
}

ul.topnav li a.active {
  background-color: #d8d8d8;
}

ul.topnav li.right {
  float: right;
}

@media screen and (max-width: 768px) {
  ul.topnav li.right,
  ul.topnav li {
    float: none;
  }
}
.gru-box {
  border-radius: 5px;
  height: 45px;
  background-color: #999;
  padding: 6px;
  margin-top: 6px;
  margin-left: 2px;
  opacity: 0.7;
  float: left;
  background: linear-gradient(60deg, #63a4c3, var(--dark-green-1));
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(255, 152, 0, 0.4);
}
.gru-blue-text {
  color: var(--dark-green-1);
  font-family: "poppins";
}
.w3-bar-block {
  /* background: url(./img/index3.jpg);*/
}
.gru-bar {
  border-radius: 5px;

  padding: 15px;
  margin-top: 23px;
  margin-left: 15px;
}
.gru-logo {
  border-bottom: 1px solid #bfbfbf;
  margin-top: 20px;
  border-top: 1px solid #bfbfbf;
  margin-top: 20px;
}
.gru-side {
  background-color: #fff;
  opacity: 0.7;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(60, 72, 88, 0.4);
}
.gru-sales-dash {
  border-radius: 5px;
  background-color: #999;
  padding: 6px;
  margin-top: -17px;
  margin-left: -12px;
  float: left;
  background: linear-gradient(60deg, #ffa726, #fb8c00);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(255, 152, 0, 0.4);
}

.gru-shiny-dash {
  border-radius: 5px;
  background-color: #999;
  padding: 6px;
  margin-top: -17px;
  margin-left: -12px;
  float: left;
  background: linear-gradient(60deg, #ec407a, #d81b60);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(233, 30, 99, 0.4);
}
.gru-sum-dash {
  border-radius: 5px;
  background-color: #999;
  padding: 6px;
  margin-top: -17px;
  margin-left: -12px;
  float: left;
  background: linear-gradient(60deg, #26c6da, #00acc1);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(76, 175, 80, 0.4);
}
.gru-fresh-dash {
  border-radius: 5px;
  background-color: #999;
  padding: 6px;
  margin-top: -17px;
  margin-left: -12px;
  float: left;
  background: linear-gradient(60deg, #66bb6a, #43a047);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(0, 188, 212, 0.4);
}
.gru-offer-dash {
  border-radius: 5px;
  background-color: #999;
  padding: 6px;
  margin-top: -17px;
  margin-left: -12px;
  float: left;
  background: linear-gradient(87deg, #5e72e4 0, #825ee4 100%);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(0, 188, 212, 0.4);
}
.gru-si-dash {
  border-radius: 5px;
  background-color: #999;
  padding: 6px;
  margin-top: -17px;
  margin-left: -12px;
  float: left;
  background: linear-gradient(87deg, #e22e4f 0, #961628 100%);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(0, 188, 212, 0.4);
}

table {
  border: 4px solid var(--color-3);
  border-collapse: collapse;
  width: 100%;
}
th {
  border: 1px solid var(--color-3);
  text-align: center;
}
td {
  border: 1px solid var(--color-3);
  text-align: center;
  color: var(--color-3);
}
.horizontal-slider {
  width: 100%;
  max-width: 100%;
}

.example-thumb {
  cursor: pointer;
  position: absolute;
  z-index: 0 !important;
  background: #ffffff;
  border: 5px solid var(--color-1);
  border-radius: 100%;
  display: block;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
}

.example-thumb.active {
  background-color: grey;
}

.example-track {
  position: relative;
  background: #ddd;
}

.example-track.example-track-0 {
  background: var(--color-1);
}

.horizontal-slider .example-track {
  top: 18px;
  height: 5px;
}

.horizontal-slider .example-thumb {
  top: 15px;
  width: 10px;
  outline: none;
  height: 10px;
  line-height: 38px;
}
@media only screen and (max-width: 600px) {
  .googleLoginMargin {
    margin-left: 20%;
  }
  .carousel-root {
    height: 40vh;
  }
  .loadingPosition {
    position: absolute;
    top: 30%;
    left: 35%;
  }
  .gretLogo {
    width: 100%;
    height: 8vh;
  }
  .buttonPositionVR {
    position: absolute;
    top: 50%;
    left: 2%;
    width: 16%;
  }
  .buttonPositionVR2 {
    position: absolute;
    top: 50%;
    right: 28%;
    width: 16%;
  }
  .create {
    display: grid;
    grid-template-columns: 15% 23% 26% 10% 25%;
  }
  .create_button {
    width: 90%;
    margin: 5%;
    padding: 12px 2%;
  }
  .create h1 {
    margin-bottom: 0;
    margin-left: "15%";
  }
  .createSteps {
    display: flex;
    margin-left: 15%;
    width: 80%;
  }
  .createSteps div img {
    width: 40%;
  }
  .createSteps div .propIconSofa {
    width: 60% !important;
  }
  .spacingCreate {
    padding-bottom: 8% !important;
  }
  .loginAndSignup {
    display: flex;
  }
  .left_section {
    padding-top: 13%;
    height: 50vh;
  }
  .right_section {
    padding-top: 5%;
    height: 50vh;
  }
  .header_family {
    font-size: 20px;
  }
}
.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}
.card {
  display: inline-block;
}

.VrCards {
}

.VrCards::-webkit-scrollbar {
  height: 8px;
}

/* Track */
.VrCards::-webkit-scrollbar-track {
  background: #d8d8d8;
}

/* Handle */
.VrCards::-webkit-scrollbar-thumb {
  background: var(--dark-blue-2);
}
.glow-on-hover:hover {
  /* box-shadow: 2px 2px 2px 2px var(--off-white-1); */
  /* text-shadow: 2px 2px var(--off-white-1); */
}

@media only screen and (min-width: 600px) {
  .buttonPositionVR {
    position: absolute;
    top: 50%;
    left: 2%;
    width: 16%;
  }
  .buttonPositionVR2 {
    position: absolute;
    top: 50%;
    right: 10%;
    width: 16%;
  }
  .designPageLayout {
    display: grid;
    color: var(--dark-blue-2);
    grid-template-columns: 12% 68% 20%;
    min-height: 100vh;
  }
  .googleLoginMargin {
    margin-left: 37%;
  }
  .carousel-root {
    height: 40vh;
  }
  .icons_style {
    width: 60%;
  }
  .icon_button {
    width: 10%;
    bottom: 3%;
    left: 0%;
    position: fixed !important;
    border: none;
    outline: none;
    background-color: inherit;
    cursor: pointer;
    padding: 0%;
  }
  .option_div {
    overflow: hidden;
    position: fixed !important;
    bottom: 3%;
    width: 5%;
    height: 0px;
    left: 2%;
    max-height: calc(-63px + 100vh);
    min-height: 50%;
    max-width: calc(-24px + 100vw);
    background-color: var(--off-white-1);
    display: grid;
    grid-template-rows: auto;
    border-radius: 8px;
    transition-delay: 1s;
    z-index: 1;
  }

  .option_button {
    width: 100%;
    border: none;
    outline: none;
    background-color: inherit;
    cursor: pointer;
    padding: 0%;
  }
  .loadingOval {
    margin-left: 46%;
  }
  .create {
    display: grid;
    grid-template-columns: 4% 7% 78% 3% 8%;
  }
  .create_button {
    width: 74%;
    margin: 5%;
    padding: 12px 2%;
  }
  .createSteps {
    display: grid;
    grid-template-columns: auto;
    margin-left: 31%;
    width: 50%;
  }
  .createSteps div img {
    width: 20%;
  }
  .spacingCreate {
    padding-bottom: 3% !important;
  }

  .loginAndSignup {
    display: flex;
  }
  .left_section {
    /* padding-top:13%; */
    height: 100vh;
  }
  .right_section {
    padding-top: 10%;
    height: 100vh;
  }
  .header_family {
    font-size: 30px;
  }
}

.black-bg {
  background-color: var(--color-1);
  color: var(--color-3);
}

.button-light-border {
  background: inherit;
  border-radius: 999px;
  /* box-shadow: var(--dark-blue-2) 0 10px 10px -10px; */
  box-sizing: border-box;
  color: var(--off-white-1);
  cursor: pointer;
  font-size: 16px;
  /* font-weight: 700; */
  line-height: 24px;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 8px 3px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
  word-break: break-word;
  border: 1px solid var(--off-white-1);
}

.button-light-border:hover {
  transform: scale(1.05);
}

.center-item {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.right-item {
  justify-content: right;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.center-item-row {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.space-item {
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
}

.space-item-column {
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.button-dark {
  background: var(--color-2);
  border-radius: 999px;
  box-shadow: var(--color-2) 0 12px 15px -10px;
  box-sizing: border-box;
  color: var(--color-3);
  cursor: pointer;
  font-size: 16px;
  /* font-weight: 700; */
  line-height: 24px;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 8px 16px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
  word-break: break-word;
  border: 0;
}

.button-dark:hover {
  box-shadow: var(--color-2) 0 10px 20px -10px;
  transform: scale(1.05);
}

.button-black {
  background: var(--color-1);
  border-radius: 999px;
  box-shadow: var(--color-2) 0 12px 15px -10px;
  box-sizing: border-box;
  color: var(--color-3);
  cursor: pointer;
  font-size: 16px;
  /* font-weight: 700; */
  line-height: 24px;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 8px 16px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
  word-break: break-word;
  border: 0;
}

.button-black:hover {
  box-shadow: var(--color-1) 0 10px 20px -10px;
  transform: scale(1.05);
}
.no-margin {
  margin: 0 !important;
}

.outline-input {
  padding: 8px;
  display: block;
  outline: 1px solid var(--color-3);
  color: var(--color-3);
  background-color: inherit;
  border-radius: 8px;
  border: none;
}

.outline-light {
  padding: 8px;
  display: block;
  color: var(--color-3);
  background-color: var(--color-3);
  color: var(--color-1);
  border-radius: 8px;
  outline: none;
  border: none;
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-file-input::before {
  background: var(--input-color);
  border-radius: 999px;
  box-sizing: border-box;
  color: var(--paragraph-color);
  display: inline-block;
  text-align: center;
  outline: none;
  white-space: nowrap;
  content: "Upload";
  cursor: pointer;
  width: 100%;
}

