@media only screen and (min-width: 600px) {
  .dashboard-card-section {
    padding: 0px 32px;
  }
}

@media only screen and (max-width: 600px) {
  .dashboard-card-section {
    padding: 0px 16px;
  }
}
.dashboard_table {
  border-radius: 8px;
  overflow: hidden;
}

table tr {
  font-weight: normal;
}

table tr.selected {
  font-weight: bolder;
}
