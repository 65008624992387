@media screen and (max-width: 600px) {
  .blue-border-cards {
    padding: 1%; 
    /* color: black; */
    width: 80%;
    height: 60%;
    border: 1px solid white;
    box-sizing: border-box;
    border-radius: 22.3629px;
    background-color: white;
    position: absolute;
    /* top: 15%; */
    /* float: right; */
    right: 10%;
    text-align: center;
    /* margin-top: -5%; */
    /* margin-left: -5%;
    /*margin-left: -5%;*/
  }

  .swal-modal {
      padding: 16px;
      color: black;
      width: 70%;
      float:right;
      margin-right: 5%;
      border: 1px solid white;
      box-sizing: border-box;
      border-radius: 22.3629px;
      background-color: #f89723;
  }

  .arrow-gif {
    width: 90px;
  }
  small {
    font-size: 10px;
  }
  .coupon_container {
    padding-top: 25%;
  }
}

@media screen and (min-width: 1000px) {
  small {
    font-size: 12px;
  }
  .coupon_container {
    padding-top: 5%;
  }
  .blue-border-cards {
    padding: 1%;
    /* color: black; */
    width: 25%;
    height: 78%;
    border: 1px solid white;
    box-sizing: border-box;
    border-radius: 22.3629px;
    background-color: white;
    position: absolute;
    top: 20%;
    float: right;
    right:42%;
  }

  /*.swal-icon img {*/
  /*  max-width: 25%;*/
  /*}*/

  .swal-modal {
      padding: 16px;
      color: black;
      width: 30%;
      float:right;
      margin-right: 5%;
      border: 1px solid white;
      box-sizing: border-box;
      border-radius: 22.3629px;
      background-color: #f89723;
  }

  .arrow-gif {
    width: 90px;
  }
}
