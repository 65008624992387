.contact-container {
  font-family: var(--fontFamily);
  background-color: var(--color-4);
  color: var(--color-3);
  /* Add more styles as needed */
}
.contact-container h1 {
  color: #000; /* Replace with actual color codes */
}

.contact-container p {
  color: #666; /* Replace with actual color codes */
}

.contact-container input,
.contact-container textarea {
  width: 100%;
  margin-bottom: 10px;
  /* Add additional styles for padding, border, etc. */
}

.custom-bg-color {
  background-color: var(--color-4);
}

.custom-text-color {
  color: var(--color-3);
}
