@media only screen and (min-width: 600px) {
  .pricing-section {
    min-height: 90vh;
    overflow: auto;
    padding: 0px 100px;
  }
}

@media only screen and (max-width: 600px) {
  .pricing-section {
    min-height: 90vh;
    overflow: auto;
    padding: 0px 16px;
  }
}
