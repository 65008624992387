/* body {
  background: url(../../public/Media/Galaxy2.png);
  background-repeat: no-repeat;
  background-size: auto;
  } */
  #planet1 {
    position: absolute;
    bottom: 5%;
    left: 5%;
    width: 25vh;
    height: 25vh;
    background: url(	https://media-public.canva.com/c0jVQ/MAERqVc0jVQ/1/s.jpg);
    border-radius: 50%;
    background-size: 100vh;
    
    box-shadow: inset 44px 16px 30px 10px rgb(0 0 0), inset 5px 0 47px 15px rgb(255 255 255 / 30%);
    animation-name: rotate;
    animation-duration: 20s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-name: rotate;
    -webkit-animation-duration: 20s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    cursor: pointer;
  }
  #planet1:hover{
    transform: scale(1.1);
  }
  @keyframes rotate {
    from {
      background-position: 0px 0px;
    }
    to {
      background-position: 610px 0px;
    }
  }
  @-webkit-keyframes rotate {
    from {
      background-position: 0px 0px;
    }
    to {
      background-position: 610px 0px;
    }
  }

@media only screen and (max-width: 600px){
  .displayGrid{
    position: absolute;
    right: 5%;
    top: 12%;
    width: 80%;
    overflow: auto;
    height: 70vh;
  }
  .bordertext{
    border-radius: 0px 0px 8px 8px;
  }
  .borderImage{
    border-radius: 8px 8px 0px 0px;
  }
  
}
@media only screen and (min-width: 600px){
   
  .displayGrid{
    position:absolute;
    right:5%;
    top:10%;
    width:35%;
    overflow: auto;
    height: 80vh;
  }
  .bordertext{
    border-radius: 8px;
  }
  .borderImage{
    border-radius: 8px;
  } 
}