@media only screen and (min-width: 600px){
    .sceneGrid{
        width:80%;
        margin-left:10%;
    }
    .gretLogo{
        width:7vh;
        height:7vh
    }
    .profiletext{
        margin-left: 80%;
        width: 15%;
        text-align: right;
    }
    .ProfileImageGrid{
        display: grid;
        width: 80%;
        margin-left: 20%;
        grid-template-columns: 70% 30%;
    }
    .SceneFavouriteGrid{
        display: grid;
        width: 60%;
        margin-left: 20%;
        grid-template-columns: 50% 50%;
    }
}
@media only screen and (max-width: 600px){
    .gretLogo{
        width:8vh;
        height:8vh
    }
    .profiletext{
        display: flex;
        margin-right: 5%;
        width: 15%;
        text-align: right;
    }
    .ProfileImageGrid{
        display: grid;
        width: 60%;
        margin-left: 20%;
        grid-template-columns: 50% 50%;
    }
    .SceneFavouriteGrid{
        display: grid;
        width: 60%;
        margin-left: 20%;
        grid-template-columns: 50% 50%;
    }
}

.hrStyle{
    border: 0;
    border-top: 2px solid var(--off-white-1);
    width: 100%;
    margin: 0!important;
}